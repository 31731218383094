//dependencias
import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import axios from 'axios';

//estilos
import './SideBarAction.css'
import Chat from '../Chat'
//componentes
import { outCliente1, outToken } from '../../accion/accion'


class SideBarAction extends Component { 
    constructor(props){
        super(props);
        this.state={
            acordion1: false,
            acordion2: false,
            acordion3: false,
            acordion4: false,
            acordion5: false,
        };
        this.verificar = this.verificar.bind(this);
    }
    verificar(e){
            // console.log([e.target.checked])
            if ((e.target.checked)===true){
               this.setState({[e.target.getAttribute('id')]: true});
            }else{
                this.setState({[e.target.getAttribute('id')]: false});

            }
    }

    cartas(){
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/infocartaPHP.php',
            responseType:'arraybuffer',
          })
            .then(function(response) {
              let blob = new Blob([response.data], { type:   'application/pdf' } )
              var fileURL = URL.createObjectURL(blob);
              window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
             
          });
    }
    evolutivo(){
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/infoevoPHP.php',
            responseType:'arraybuffer',
          })
            .then(function(response) {
              let blob = new Blob([response.data], { type:   'application/pdf' } )
              var fileURL = URL.createObjectURL(blob);
              window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
             
          });
    }
    ingreso(){
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/infoingPHP.php',
            responseType:'arraybuffer',
          })
            .then(function(response) {
              let blob = new Blob([response.data], { type:   'application/pdf' } )
              var fileURL = URL.createObjectURL(blob);
              window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
             
          });
    }
    egreso(){
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/infoegrePHP.php',
            responseType:'arraybuffer',
          })
            .then(function(response) {
              let blob = new Blob([response.data], { type:   'application/pdf' } )
              var fileURL = URL.createObjectURL(blob);
              window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
             
          });
    }
    quirurgico(){
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/infoquiruPHP.php',
            responseType:'arraybuffer',
          })
            .then(function(response) {
              let blob = new Blob([response.data], { type:   'application/pdf' } )
              var fileURL = URL.createObjectURL(blob);
              window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
             
          });
    }
    estandar(){
        axios({
            method:'post',
            url:'https://fehensa.com/consultorio2/fpdf/reportes/infoestaPHP.php',
            responseType:'arraybuffer',
          })
            .then(function(response) {
              let blob = new Blob([response.data], { type:   'application/pdf' } )
              var fileURL = URL.createObjectURL(blob);
              window.open(fileURL);
            //   let link = document.createElement('a')
            //   link.href = window.URL.createObjectURL(blob)
            //   link.download = 'reporte.pdf'
            //   link.click()
             
          });
    }

    cerrarsesion(){

        //console.log('https://fehensa.com/ServiciosWeb/eliminarToken.php/?pas=' + this.props.usuario1.password+'&tok='+this.props.token.token)
        axios.get('https://fehensa.com/ServiciosWeb/eliminarToken.php/?pas=' + this.props.usuario1.password+'&tok='+this.props.token.token)
        .then(res => {
            let tokenp = res.data.tokenEli

            if (tokenp ===true) {
                //console.log('resp-token - '+tokenp)

                this.props.outCliente1();
                this.props.outToken();
            }else {
                //console.log('Usuario no Tiene Token');
            }
        })
        .catch(err => {
            //console.log('error - '+err.response.statu);
            if (err.response.status === 404) {
                //console.log('el usuario no existe ')
            }
            
        });

    }
    prueba(){
        return(
            <Redirect to='/medicamento' /> 
        )
    }

    
render(){
    // console.log('acordion1 - '+this.state.acordion1)
    return(
        <div>
            <div className="contenedor-menus">
                <Link to="#" className="btn-menu">Menu <i className="icono fas fa-bars"></i></Link>
                <div className="menu">
                    <ul>
                        <li className="item"><Link to='/historia' className="btn" ><i className="icono izquierda far fa-newspaper"></i>Historia</Link></li>
                    
                        <li className="item">
                            <label className="btn" htmlFor="acordion1">
                                <i className="icono izquierda fas fa-file-medical" />
                                Informes/Reposos
                                {(this.state.acordion1===true )
                                ? <i className="icono-derecha-up fas fa-chevron-up"/> 
                                : <i className="icono derecha fas fa-chevron-down"/>
                                }
                            </label>
                            <input type="checkbox" id="acordion1" checked={this.state.acordion1} onChange={this.verificar} />

                            <div className="smenu" id="informe">
                                <ul>
                                    <li><Link to="/reposomedico">Reposo Medico</Link></li>
                                    <li><Link to="/constanciamedica">Constancia Medica</Link></li>
                                    <li><Link to="/informeingreso">Informe Ingreso</Link></li>
                                    <li><Link to="/informe">Egreso/Evolutivo/Quirurgico</Link></li>
                                    <li><Link to="/referencia">Informe Referidor</Link></li>
                                    <li><Link to="/estupefacientes">Informe Psicotropicos</Link></li>
                                </ul>
                            </div>
                        </li>

                        <li className="item" >
                            <label className="btn" htmlFor="acordion2">
                                <i className="icono izquierda far fa-edit" />
                                Listados
                                {(this.state.acordion2===true )
                                ? <i className="icono-derecha-up fas fa-chevron-up"/> 
                                : <i className="icono derecha fas fa-chevron-down"/>
                                }
                            </label>
                            <input type="checkbox" id="acordion2" checked={this.state.acordion2} onChange={this.verificar}/>

                            <div className="smenu" id="listado">
                                <ul>
                                    <li><Link to="/casos">Maestro de Casos</Link></li>
                                    <li><Link to="/estadistica">Estadistica</Link></li>
                                </ul>
                            </div>
                        </li>

                        {/* <li className="item" ><Link to="/espera" className="btn"><i className="icono izquierda fas fa-user-clock"></i>Lista de Espera</Link></li> */}
                        <li className="item" >
                            <label className="btn" htmlFor="acordion3">
                                <i className="icono izquierda fas fa-user-clock" />
                                Lista de Espera
                                {(this.state.acordion3===true )
                                    ? <i className="icono-derecha-up fas fa-chevron-up"/> 
                                    : <i className="icono derecha fas fa-chevron-down"/>
                                }
                            </label>
                            <input type="checkbox" id="acordion3" checked={this.state.acordion3} onChange={this.verificar}/>

                            <div className="smenu" id="listaespera">
                                <ul>
                                    <li><Link to="/espera">Crear Citas</Link></li>
                                    <li><Link to="/citas">Ordenar Citas del Dia</Link></li>
                                </ul>
                            </div>
                        </li>
                        
                        
                        
                        <li className="item" >
                            <label className="btn" htmlFor="acordion4">
                                <i className="icono izquierda far fa-edit" />
                                Informes
                                {(this.state.acordion4===true )
                                    ? <i className="icono-derecha-up fas fa-chevron-up"/> 
                                    : <i className="icono derecha fas fa-chevron-down"/>
                                }
                            </label>
                            <input type="checkbox" id="acordion4" checked={this.state.acordion4} onChange={this.verificar}/>

                            <div className="smenu" id="crearinforme">
                                <ul>
                                    <li><Link to="#">Crear Informes</Link></li>
                                    <li><Link to="#">Imprimir Informes</Link></li>
                                </ul>
                            </div>
                        </li>

                        <li className="item" >
                            <label className="btn" htmlFor="acordion5" >
                                <i className="icono izquierda fas fa-folder-open" />
                                Membretes
                                {(this.state.acordion5===true )
                                    ? <i className="icono-derecha-up fas fa-chevron-up"/> 
                                    : <i className="icono derecha fas fa-chevron-down"/>
                                }
                            </label>
                            <input type="checkbox" id="acordion5" checked={this.state.acordion5} onChange={this.verificar}/>

                            <div className="smenu" id="membrete">
                                <ul>
                                    <li><Link to="#" onClick={this.cartas.bind(this)} >Para Cartas</Link></li>
                                    <li><Link to="#" onClick={this.evolutivo.bind(this)} >Informe Evolutivo</Link></li>
                                    <li><Link to="#" onClick={this.ingreso.bind(this)}>Informe Ingreso</Link></li>
                                    <li><Link to="#" onClick={this.egreso.bind(this)}>Informe Egreso</Link></li>
                                    <li><Link to="#" onClick={this.quirurgico.bind(this)}>Informe Quirurgico</Link></li>
                                    <li><Link to="#" onClick={this.estandar.bind(this)}>Estandar</Link></li>
                                </ul>
                            </div>
                            
                        </li>

                        <li className="item"><Link to="/medicamento" className="btn" ><i className="icono izquierda far fa-newspaper"></i>Crear Medicamentos</Link></li>

                        <li className="item">
                            {/* <Link to="#" className="btn"><i className="icono izquierda fas fa-sign-in-alt"></i>Iniciar Sesion</Link> */}
                            <Link to="/" className="btn" onClick={this.cerrarsesion.bind(this)}><i className="icono izquierda fas fa-sign-out-alt"></i>Cerrar Sesion</Link>
                        </li>
                    </ul>
                </div>
                <Chat />
            </div>
        </div>
    );
}
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token,
    }
}

const mapDispatchToProps = {
        outCliente1,
        outToken
}

export default connect(mapStateToProps,mapDispatchToProps)(SideBarAction);