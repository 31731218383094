//dependencias
import React from 'react';
import { Route, Switch } from 'react-router-dom';

//componentes


// import Nosotros from './components/Nosotros';
// import Productos from './components/Productos';
// import Pedido from './components/Pedido';
// import Cliente from './components/Cliente';
import Casos from './components/Casos';
import Estadisticas from './components/Estadisticas';
import Estupefacientes from './components/Estupefacientes';
import Referencia from './components/Referencia';
import Informe from './components/Informe';
import InformeIngreso from './components/InformeIngreso';
import ConstanciaMedica from './components/ConstanciaMedica';
import ReposoMedico from './components/ReposoMedico';
import CitasDia from './components/Citas';
import Inicio from './components/Inicio';
import Espera from './components/Espera';
import Login from './components/Login';
import Historia from './components/Historia/';
import Medicamento from './components/Medicamento/';
import Pagina404 from './components/Pagina404';

const AppRoutes = () => 
        <Switch>
            {/*<Route exact path="/registro" component={Registro} />
             <Route exact path="/ayuda" component={Ayuda} />
            <Route exact path="/nosotros" component={Nosotros} />*/}
            <Route exact strict path="/casos" component={Casos} />
            <Route exact strict path="/estadistica" component={Estadisticas} />
            <Route exact strict path="/estupefacientes" component={Estupefacientes} />
            <Route exact strict path="/referencia" component={Referencia} />
            <Route exact strict path="/informe" component={Informe} />
            <Route exact strict path="/informeingreso" component={InformeIngreso} />
            <Route exact strict path="/constanciamedica" component={ConstanciaMedica} />
            <Route exact strict path="/reposomedico" component={ReposoMedico} />
            <Route exact strict path="/citas" component={CitasDia} />
            <Route exact strict path="/espera" component={Espera} />
            <Route exact strict path="/medicamento" component={Medicamento} />
            <Route exact strict path="/historia" component={Historia} /> 
            <Route exact strict path="/login" component={Login} />
            <Route exact strict path="/" component={Inicio} />
            <Route component={Pagina404} />
        </Switch>   
export default AppRoutes;