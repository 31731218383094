import React, {Component}  from 'react';
import moment from "moment";
import axios from 'axios';
import {Link} from "react-router-dom";
// import Countdown from 'react-countdown-now';

import './Reposome.css';
import Alert from '../../modal/alert'

class Reposome extends Component {
  constructor(props){
    super(props);
    this.state = {
        cedula:'',
        nombre: '',
        edad: '',
        fechad: moment().format('YYYY-MM-DD'),
        fechah: moment().format('YYYY-MM-DD'),
        diagnostico1:'',
        tratamiento1:'',
        diasr:'',
        mensajealerta:false,
        opfirma:'NO',
    }
  }
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }
    guardar=()=>{
        if (parseFloat(this.state.cedula) >0) {
            let data = new FormData();
            data.append('cedula', this.state.cedula);
            data.append('nombre', this.state.nombre);
            data.append('edad', this.state.edad);
            data.append('diagnostico', this.state.diagnostico1);
            data.append('tratamiento', this.state.tratamiento1);
            data.append('diasreposo', this.state.diasr);
            data.append('desde', this.state.fechad);
            data.append('hasta', this.state.fechah);
            data.append('tipoinforme', 'REPOSOMEDICO');
            data.append('firma', this.state.opfirma);
            data.append('boton', 'Adicionar');
    
            axios({
                method:'post',
                url:'https://fehensa.com/consultorio2/informepacientesPHP.php',
                data: data
            })
            .then(res => {
                //console.log(res.data.variable1)
                if (res.data.variable1) {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Envio de Correos',
                        cuerpoM: 'El correo se envio exitosamente a el paciente',
                    })
                    
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Envio de Correo',
                        cuerpoM: 'Hubo un error, No se envio el correo a el paciente, verifique por favor',
                    })
                }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Envio de Correo',
                cuerpoM: 'Debe seleccionar un paciente',
            })
        }
    }

 
imprimir=()=>{

    let data = new FormData();
    data.append('cedu', this.state.cedula);
    data.append('nombre', this.state.nombre);
    data.append('edad', this.state.edad);
    data.append('diagnostico', this.state.diagnostico1);
    data.append('tratamiento', this.state.tratamiento1);
    data.append('dias', this.state.diasr);
    data.append('desde', this.state.fechad);
    data.append('hasta', this.state.fechah);
    data.append('firma', this.state.opfirma);
    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/reposomePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          blob.name = '7146761.pdf';
          var fileURL = URL.createObjectURL(blob,'7146761.pdf');
          window.open(fileURL);
        //   let link = document.createElement('a')
        //   link.href = window.URL.createObjectURL(blob)
        //   link.download = 'reporte.pdf'
        //   link.click()
         
      });

    // axios.post('https://fehensa.com/clinica/fpdf/reportes/infocartaPHP.php')
    // .then(res => {
    //     console.log(res.data)
     
    // })    

}

consultar(e) {
    if (parseFloat(this.state.cedula) > 0 || parseFloat(e)>0) {
        let jcedul=0;
        if (parseFloat(this.state.cedula) > 0){
            jcedul=parseFloat(this.state.cedula);
        } 
        if (parseFloat(e)>0){
            console.log("xpaso1: "+parseFloat(e));
            jcedul=parseFloat(e);
        } 
        axios.post('https://fehensa.com/consultorio2/historia.php/?cedula=' + jcedul + '&boton=Consultar')
        //console.log(('https://fehensa.com/consultorio2/historia.php/?cedula=' + this.state.cedula + '&boton=Consultar'))    
        .then(res => {
                if (res.data) {
                    this.setState({
                        clientetrue:false,
                        citaOn:false,
                        cedula: String(res.data.variable1),
                        nombre: String(res.data.variable3)+" "+String(res.data.variable4),
                        edad: String(res.data.variable6),
                    })
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Reposo Medico',
                        cuerpoM: 'No se encontro la cedula que se desea consultar, verifique Gracias...',
                     })
                }
            })
    } else {
        this.setState({
            mensajealerta: true,
            tituloM: 'Menu Reposo Medico',
            cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
        })

    }
}

handleClose = () => {
    this.setState({ mensajealerta: false })
  }


  render(){
      return(
        <div className="row">  
            <div className="cont-quimioc col-12">

                {this.state.mensajealerta === true &&
                    <Alert
                    tituloMensaje={this.state.tituloM}
                    cuerpoMensaje={this.state.cuerpoM}
                    open={this.state.mensajealerta}
                    handleClose={this.handleClose}
                    />
                }  

                <div className="row">
                    <div className="cont-tituloquimioc col-12">    
                        <span className="tituloquimioc">Reposo Medico</span>
                        <Link to="/"><span className="titulo-cerrarquimioc">X</span></Link>
                    </div>
                </div>
            
                <div className='row'>
                    <div className="cont-opcion col-3">
                        <label className="opcion">Cedula.</label>
                        <input
                            disabled={this.state.consulto}
                            className="form-control"
                            type="text"
                            name="cedula"
                            id="cedula"
                            autoComplete="off"
                            pattern="[0-9] required"
                            value={this.state.cedula}
                            onChange={this.onChange.bind(this)}
                            onKeyPress={event => { if (event.key === 'Enter') {this.consultar()} }}
                        />
                        <i className="icono-izquierdac fa fa-search" onClick={this.consultar.bind(this)}> </i>
                    </div>
                    <div className="cont-opcion col-1">
                        <label className="opcion" >Edad.</label>
                        <input
                            className="form-control"
                            type="text"
                            name="edad"
                            id="edad"
                            onChange={this.onChange.bind(this)}
                            value={this.state.edad}
                        />
                    </div>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Nombre.</label>
                        <input
                            className="form-control"
                            type="text"
                            name="nombre"
                            id="nombre"
                            autoComplete="off"
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombre}
                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Diagnostico.</label>
                        <textarea
                            className="form-control"
                            name="diagnostico1"
                            id="diagnostico1"
                            rows="3"
                            cols="135"
                            value={this.state.diagnostico1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Tratamiento.</label>
                        <textarea
                            className="form-control"
                            name="tratamiento1"
                            id="tratamiento1"
                            rows="3"
                            cols="135"
                            value={this.state.tratamiento1}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                </div>
                <div className='row'>    
                    <div className="cont-opcion col-2">
                            <label className="opcion" >Dias Reposo.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="diasr"
                                id="diasr"
                                value={this.state.diasr}
                                onChange={this.onChange.bind(this)}
                            />
                    </div>
                    <div className="cont-opcion col-2">
                        <label className="opcion" >Fecha desde.</label>
                        <input  
                            className="form-control"  
                            name="fechad"
                            id="fechad"
                            type="date" 
                            value={ moment(this.state.fechad).format("YYYY-MM-DD") } 
                            onChange={ this.onChange.bind(this) } 
                        />
                    </div>

                    <div className="cont-opcion col-2">
                        <label className="opcion" >Fecha Hasta.</label>
                        <input
                            className="form-control"
                            name="fechah"
                            id="fechah"
                            type="date" 
                            value={ moment(this.state.fechah).format("YYYY-MM-DD") } 
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className="representante col-5">
                                    <label className="labelantecedentesescolar">Imprimir Firma. </label>
                                    <label className="titulosn">
                                        <input
                                            type="radio"
                                            className="opfirmasi"
                                            name="opfirma"
                                            id="opfirma"
                                            value="SI"
                                            checked={this.state.opfirma === 'SI'}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    SI</label>
                                    <label className="titulosn">
                                        <input
                                            type="radio"
                                            className="opfirmano"
                                            name="opfirma"
                                            id="opfirma"
                                            value="NO"
                                            checked={this.state.opfirma === 'NO'}
                                            onChange={this.onChange.bind(this)}
                                        />
                                    NO</label>
                                </div>

                </div>
            
                <div className="row">
                    <div className="botonesgeneral col-12">
                        <button className="botongr" onClick={this.guardar.bind(this)}>Enviar al Correo</button>
                        <button className="botongr" onClick={this.imprimir.bind(this)}>Imprimir</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
export default Reposome;  