import React, {Component}  from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import AnyChart from 'anychart-react'
import RingLoader from 'react-spinners/ScaleLoader';


//import anychart from 'anychart'
//import moment from "moment";
// import Countdown from 'react-countdown-now';

import './Estadistica.css';
import Alert from '../../modal/alert'

class EstadisticaAction extends Component {
  constructor(props){
    super(props);
    this.state = {
        mes:'',
        ano:'',
        sexof:'F',
        sexom:'',
        edaddesde:'10',
        edadhasta:'99',
        iedaddesde:[],
        iedadhasta:[],
        casos:'',
        icasos:[],
        inombres:[],
        imes:['01','02','03','04','05','06','07','08','09','10','11','12'],
        iano:[],
        iestadistica:['10-15', '16-21', '22-27', '28-33','34-39', '40-45', '46-51', '52-57', '58-63', '64-69', '70-75', '76-81', '82-87', '88-93', '+94'],
        datae1:'',
        titulog1:'',
        datae2:'',
        titulog2:'',
        datae:'',
        mensajealerta:false,
        barraOn:true,
        titulog:'',
        loading: false,
        barra1fOn:false,
        barra3aOn:false,
    }
    
}


onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  onChangef(e) {
    //console.log(e.target.checked)
    if (e.target.checked){
        this.setState({
            [e.target.name]:"F"
        })
    }else{
        this.setState({
            [e.target.name]: ""
        })
    }    
  }
  onChangem(e) {
    //console.log(e.target.checked)
    if (e.target.checked){
        this.setState({
            [e.target.name]:"M"
        })
    }else{
        this.setState({
            [e.target.name]: ""
        })
    }    
  }

  componentDidMount(){
    let cl=0;
    let i=0;
    let valores1=[];
    for(i=2002; i<2051;i++){
        valores1[cl]=i;
        cl++
    }

    cl=0;
    let valores2=[];
    for(i=10; i<100;i++){
        valores2[cl]=i;
        cl++
    }

    cl=0;
    let valores3=[];
    for(i=99; i>14;i--){
        valores3[cl]=i;
        cl++
    }

    this.setState({
        iano: valores1,
        iedaddesde: valores2,
        iedadhasta: valores3,
    });

    this.buscarcasos();
    
  }

  handleClose = () => {
    this.setState({ mensajealerta: false })
  }

    buscarcasos=()=>{
        let data = new FormData();
        data.append('boton', 'BuscarCasos');

        let config = {
            headers : {
              'Content-Type' : 'multipart/form-data'
            }
        }
        const xurl = 'https://fehensa.com/consultorio2/estadisticaPHP.php';
        axios.post(xurl, data, config)
        .then(res => {
            if (res.data) {
                let xcasos = res.data

                this.setState({
                    icasos:xcasos
                })
                
            }
        })
    }

    estadistica1=()=>{
        if (this.state.casos.length>0 & this.state.sexof.length>0 || this.state.casos.length>0 & this.state.sexom.length>0 ) {
            //if(this.state.mes.length>0 & this.state.ano.length>0) {
                let datas = new FormData();
                datas.append('mes', this.state.mes);
                datas.append('ano', this.state.ano);
                datas.append('f', this.state.sexof);
                datas.append('m', this.state.sexom);
                datas.append('desde', this.state.edaddesde);
                datas.append('hasta', this.state.edadhasta);
                datas.append('caso', this.state.casos);
                datas.append('boton', 'Buscar');

                let config = {
                    headers : {
                    'Content-Type' : 'multipart/form-data'
                    }
                }
                const xurl = 'https://fehensa.com/consultorio2/estadisticaPHP.php';
                axios.post(xurl, datas, config)
                .then(res => {
                    console.log(res.data)
                    if (res.data) {
                        let xnombres = res.data.variable1
                        if(res.data.variable3==='1f') this.llenar1f(res.data.variable2.variable1);
                        if(res.data.variable3==='3a') this.llenar3a(res.data.variable2.variable1);
                        this.setState({
                            inombres:xnombres,
                            loading:false,
                        })
                        
                    }
                })
            //}
        }else{
            this.setState({
                loading:false,
                mensajealerta: true,
                tituloM: 'Estadiscicas de Pacientes',
                cuerpoM: 'Debe elegir los campos correctos, al menos un tipo de sexo, seleccionar un tipo de caso y verificar que si incluye una fecha en el mes debe indicar el año Gracias.',
              })
        }
    }

    llenar3a=(xres1)=>{
        let sumat1=0;
        let sumat2=0;
        const data1 = [];
        const data2 = [];
        for (let i=0; i<15; i++){           
            if (parseInt(xres1[i])>0){
                    sumat1+=parseInt(xres1[i]);
                    data1.push([this.state.iestadistica[i], xres1[i]]);
            }
        }
        for (let j=15; j<30; j++){           
            if (parseInt(xres1[j])>0){
                    sumat2+=parseInt(xres1[j]);
                    data2.push([this.state.iestadistica[j-15], xres1[j]]);
            }
        }

        //console.log(data)
        this.setState({barra3aOn:true, datae1:data1, titulog1:'Muestreo Aplicado a un Universo de: '+sumat1+' Pacientes Femenino'})    
        this.setState({datae2:data2,titulog2:'Muestreo Aplicado a un Universo de: '+sumat2+' Pacientes Masculino'})    
    }


    llenar1f=(xres)=>{
        let sumat=0;
        const data = [];
        for (let i=0; i<15; i++){           
            if (parseInt(xres[i])>0){
                    sumat+=parseInt(xres[i]);
                    data.push([this.state.iestadistica[i], xres[i]]);
            }
        }
        //console.log(data)
        this.setState({barra1fOn:true, datae:data,titulog:'Muestreo Aplicado a un Universo de: '+sumat+' Pacientes'})    
    }

    actualizarbarraOn=()=>{
        if (this.state.barraOn){
            this.setState({barraOn:false})
        }else{
            this.setState({barraOn:true})
        }

    }

    limpiar=()=>{
        this.setState({
            mes:'',
            ano:'',
            sexof:'F',
            sexom:'',
            edaddesde:'10',
            edadhasta:'99',
            casos:'',
            inombres:[],
            iestadistica:['10-15', '16-21', '22-27', '28-33','34-39', '40-45', '46-51', '52-57', '58-63', '64-69', '70-75', '76-81', '82-87', '88-93', '+94'],
            datae:'',
            mensajealerta:false,
            barraOn:true,
            titulog:'',
            loading: false,
            barra1fOn:false,
            barra3aOn:false,
        })
    }

    spineractivar(){
        if (this.state.loading===true){
          this.setState({
            loading:false,
          })
        }else{
          this.setState({
            loading:true,
            barra1fOn:false,
            barra3aOn:false,
            inombres:[],

          })
            this.estadistica1()
        }
      }

  render(){
    
      return(
          <div className="contp-estadistica row">
              <div className="estadistica col-12">
                    {this.state.mensajealerta === true &&
                        <Alert
                            tituloMensaje={this.state.tituloM}
                            cuerpoMensaje={this.state.cuerpoM}
                            open={this.state.mensajealerta}
                            handleClose={this.handleClose}
                        />
                    }  

                    {this.state.loading === true &&
                        <div className="cont-spinner row">
                            <div className='spinner col-6'>
                            <RingLoader
                                clasName="spinner"
                                sizeUnit={"px"}
                                size={160}
                                width={19}
                                radius={20}
                                height={160}
                                color={'#48e120'}
                                loading={this.state.loading}
                            />
                            </div>
                        </div>
                    }
                   
                    <div className="cont-tituloestadistica col-12">    
                        <span className="tituloquimioc">Estadisticas</span>
                        <Link to="/"><span className="titulo-cerrarquimioc">X</span></Link>
                    </div>
                   
                   <div className="row">
                        <div className="cont-opcion col-1">
                            <label className="opcion" >Mes.</label>
                            <select id="mes" name="mes" value={this.state.mes} onChange={this.onChange.bind(this)}>
                                <option></option>
                                {this.state.imes.map((item, i)=>{
                                    return<option key={i} value={item}>{item }</option>
                                })}
                            </select>
                        </div>            
                        <div className="cont-opcion col-1">
                            <label className="opcion" >Año.</label>
                            <select id="ano" name="ano" value={this.state.ano} onChange={this.onChange.bind(this)}>
                                <option></option>
                                {this.state.iano.map((item, i)=>{
                                    return<option key={i} value={item}>{item }</option>
                                })}
                            </select>
                        </div>            

                        <div className="cont-opcion2 col-1">
                            <label className="opcion" >Femenino.</label>
                            <input
                                className="form-control"
                                type="checkbox"
                                name="sexof"
                                id="sexof"
                                value="F"
                                checked={this.state.sexof==='F'}
                                onChange={this.onChangef.bind(this)}
                            />
                        </div>
                        <div className="cont-opcion2 col-1">
                            <label className="opcion" >Masculino.</label>
                            <input
                                className="form-control"
                                type="checkbox"
                                name="sexom"
                                id="sexom"
                                value="M"
                                checked={this.state.sexom==='M'}
                                onChange={this.onChangem.bind(this)}
                            />
                        </div>

                        <div className="cont-opcion col-1">
                            <label className="opcione" >Edad Desde</label>
                            <select id="edaddesde" name="edaddesde" value={this.state.edaddesde} onChange={this.onChange.bind(this)}>
                                {this.state.iedaddesde.map((item, i)=>{
                                    return<option key={i} value={item}>{item }</option>
                                })}
                            </select>
                        </div>            
                        <div className="cont-opcion col-1">
                            <label className="opcione" >Edad Hasta</label>
                            <select id="edadhasta" name="edadhasta" value={this.state.edadhasta} onChange={this.onChange.bind(this)}>
                                {this.state.iedadhasta.map((item, i)=>{
                                    return<option key={i} value={item}>{item }</option>
                                })}
                            </select>
                        </div>   

                        <div className="cont-opcion col-4">
                            <label className="opcion" >Seleccione el tipo de Caso.</label>
                            <select id="casos" name="casos" value={this.state.casos} onChange={this.onChange.bind(this)}>
                                <option></option>
                                {this.state.icasos.map((item, i)=>{
                                    return<option key={i} value={item.variable1}>{item.variable2 }</option>
                                })}
                            </select>
                        </div>  
                        <div className="accionese col-1">
                            <button className="boton" onClick={this.spineractivar.bind(this)}>Consultar</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cont-tituloestadistica col-4">
                            <span className="tituloquimioc">Pacientes</span>
                            <div className="row">
                                <div className="cont-principal-tablas col-12">
                                    <div className="row">
                                        <div className="cont-general-citas col-12">
                                            <div className="cont-titu-esta">
                                                <span className="tituloest1">Cedula</span>
                                                <span className="tituloest2">Nombre Paciente</span>
                                            </div>
                                            <div className="listadocasos">
                                                <table className="tablaespera1">
                                                    <tbody className="cuerpoespera1">
                                                        {this.state.inombres.map((item, i) => {
                                                            // console.log(item.variable1)
                                                            return <tr className={i%2 === 0 ? "odd" : ""} key={item.variable1}><td width="150" className="itemtabla1" >{item.variable1}</td>
                                                            <td width="650" className="itemtabla1">{item.variable2}</td></tr> 
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.barraOn===true && this.state.barra3aOn===true &&                                
                            <div className="cont-tituloestadistica col-8">
                                <span className="tituloquimioc">Grafica</span>
                                <button className="botonest" onClick={this.actualizarbarraOn.bind(this)}>Cambiar a Torta</button>
                                <button className="botonest" onClick={this.limpiar.bind(this)}>Limpiar Datos</button>
                                <AnyChart type="column" title={this.state.titulog1} width={700} height={175} legend={true} data={this.state.datae1}/>
                                <AnyChart type="column" title={this.state.titulog2} width={700} height={175} legend={true} data={this.state.datae2}/>
                            </div>
                        } 
                        {this.state.barraOn===false && this.state.barra3aOn===true &&                                
                            <div className="cont-tituloestadistica col-8">
                                <span className="tituloquimioc">Grafica</span>
                                <button className="botonest" onClick={this.actualizarbarraOn.bind(this)}>Cambiar a Barra</button>
                                <button className="botonest" onClick={this.limpiar.bind(this)}>Limpiar Datos</button>
                                <AnyChart type="pie" title={this.state.titulog1} width={700} height={175} legend={true} data={this.state.datae1}/>
                                <AnyChart type="pie" title={this.state.titulog2} width={700} height={175} legend={true} data={this.state.datae2}/>
                            </div>
                        } 

                        {this.state.barraOn===true && this.state.barra1fOn===true &&                                
                            <div className="cont-tituloestadistica col-8">
                                <span className="tituloquimioc">Grafica</span>
                                <button className="botonest" onClick={this.actualizarbarraOn.bind(this)}>Cambiar a Torta</button>
                                <button className="botonest" onClick={this.limpiar.bind(this)}>Limpiar Datos</button>

                                <AnyChart type="column" title={this.state.titulog} width={700} height={350} theme='Light Blue' legend={true} data={this.state.datae}/>
                                
                            </div>
                        } 
                        {this.state.barraOn===false && this.state.barra1fOn===true &&                                
                            <div className="cont-tituloestadistica col-8">
                                <span className="tituloquimioc">Grafica</span>
                                <button className="botonest" onClick={this.actualizarbarraOn.bind(this)}>Cambiar a Barra</button>
                                <button className="botonest" onClick={this.limpiar.bind(this)}>Limpiar Datos</button>
                                {/* <AnyChart data={data} />  */}
                                <AnyChart type="pie" title={this.state.titulog} width={700} height={350} legend={true} data={this.state.datae}/>
                                
                                {/* <AnyChart {...complexSettings} /> */}
                            </div>
                        } 
                    </div>
              </div>
          </div>
      )
  }
}
export default EstadisticaAction;