import React, {Component}  from 'react';
import moment from "moment";
import axios from 'axios';
import {Link} from "react-router-dom";
// import Countdown from 'react-countdown-now';

import './Informeingreso.css';
import Alert from '../../modal/alert'

class Informeingreso extends Component {
  constructor(props){
    super(props);
    this.state = {
        cedula2:'',
        nombre2: '',
        edad2: '',
        fechad2: moment().format('YYYY-MM-DD'),
        diagnostico2:'',
        sintoma2:'',
        diase2:'',
        diash2:'',
        examenf2:'',
        laboratorio2:'',
        ecog2:'',
        endoscopia2:'',
        ninguno2:'',
        anexos2:'',
        radiologia2:'',
        tac2:'',
        otros2:'',
        biopsia2:'',
        quirurgico2:'',
        medico2:'',
        procedimiento2:'',
        mensajealerta:false,
        opfirma:'NO',
    }
  }
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
        [e.target.name]: e.target.value
    })
  }
  onChange2(e) {
    //console.log(e.target.checked)
    if (e.target.checked){
        this.setState({
            [e.target.name]:true
        })
    }else{
        this.setState({
            [e.target.name]: false
        })
    }    
  }

guardar=()=>{
    if (parseFloat(this.state.cedula2) >0) {
        let data = new FormData();
        data.append('cedula', this.state.cedula2);
        data.append('nombre', this.state.nombre2);
        data.append('edad', this.state.edad2);
        data.append('diagnostico', this.state.diagnostico2);
        data.append('desde', this.state.fechad2);
        data.append('sintomatologia', this.state.sintoma2);
        data.append('tevolucion', this.state.diase2);
        data.append('thospitalizacion', this.state.diash2);
        data.append('hallasgos', this.state.examenf2);
        data.append('laboratorio', this.state.laboratorio2);
        data.append('ecografia', this.state.ecog2);
        data.append('endoscopia', this.state.endoscopia2);
        data.append('ninguno', this.state.ninguno2);
        data.append('anexos', this.state.anexos2);
        data.append('radiologia', this.state.radiologia2);
        data.append('tac', this.state.tac2);
        data.append('otros', this.state.otros2);
        data.append('biopsia', this.state.biopsia2);
        data.append('quirurgico', this.state.quirurgico2);
        data.append('medico', this.state.medico2);
        data.append('procedimiento', this.state.procedimiento2);
        data.append('tipoinforme', 'INGRESO');
        data.append('firma', this.state.opfirma);

        data.append('boton', 'Adicionar');

        let config = {
            headers : {
              'Content-Type' : 'multipart/form-data'
            }
        }
        const xurl = 'https://fehensa.com/consultorio2/informepacientesPHP.php';
        axios.post(xurl, data, config)
        .then(res => {
            if (res.data.variable1) {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Envio de Correos',
                    cuerpoM: 'El correo se envio exitosamente a el paciente',
                })
                
            } else {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Envio de Correo',
                    cuerpoM: 'Hubo un error, No se envio el correo a el paciente, verifique por favor',
                })
            }
        })
    }else {
        this.setState({
            mensajealerta: true,
            tituloM: 'Menu Envio de Correo',
            cuerpoM: 'Debe seleccionar un paciente',
        })
    }
}

 
imprimir=()=>{

    let data = new FormData();
    data.append('cedu', this.state.cedula2);
    data.append('nombre', this.state.nombre2);
    data.append('edad', this.state.edad2);
    data.append('diagnostico', this.state.diagnostico2);
    data.append('desde', this.state.fechad2);
    data.append('sintoma', this.state.sintoma2);
    data.append('tevo', this.state.diase2);
    data.append('thosp', this.state.diash2);
    data.append('fisico', this.state.examenf2);
    data.append('labo', this.state.laboratorio2);
    data.append('eco', this.state.ecog2);
    data.append('endo', this.state.endoscopia2);
    data.append('ninguno', this.state.ninguno2);
    data.append('anexo', this.state.anexos2);
    data.append('radio', this.state.radiologia2);
    data.append('tac', this.state.tac2);
    data.append('otros', this.state.otros2);
    data.append('biopsia', this.state.biopsia2);
    data.append('quiru', this.state.quirurgico2);
    data.append('medico', this.state.medico2);
    data.append('proce', this.state.procedimiento2);
    data.append('firma', this.state.opfirma);


    axios({
        method:'post',
        url:'https://fehensa.com/consultorio2/fpdf/reportes/repoingrePHP.php',
        responseType:'arraybuffer',
        data: data
      })
        .then(function(response) {
          let blob = new Blob([response.data], { type:   'application/pdf' } )
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        //   let link = document.createElement('a')
        //   link.href = window.URL.createObjectURL(blob)
        //   link.download = 'reporte.pdf'
        //   link.click()
         
      });

    // axios.post('https://fehensa.com/clinica/fpdf/reportes/infocartaPHP.php')
    // .then(res => {
    //     console.log(res.data)
     
    // })    

}

consultar(e) {
    if (parseFloat(this.state.cedula2) > 0 || parseFloat(e)>0) {
        let jcedul=0;
        if (parseFloat(this.state.cedula2) > 0){
            jcedul=parseFloat(this.state.cedula2);
        } 
        if (parseFloat(e)>0){
            console.log("xpaso1: "+parseFloat(e));
            jcedul=parseFloat(e);
        } 
        axios.post('https://fehensa.com/consultorio2/historia.php/?cedula=' + jcedul + '&boton=Consultar')
        //console.log(('https://fehensa.com/consultorio2/historia.php/?cedula=' + this.state.cedula + '&boton=Consultar'))    
        .then(res => {
                if (res.data) {
                    this.setState({
                        clientetrue:false,
                        citaOn:false,
                        cedula2: String(res.data.variable1),
                        nombre2: String(res.data.variable3)+" "+String(res.data.variable4),
                        edad2: String(res.data.variable6),
                    })
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Reposo Medico',
                        cuerpoM: 'No se encontro la cedula que se desea consultar, verifique Gracias...',
                     })
                }
            })
    } else {
        this.setState({
            mensajealerta: true,
            tituloM: 'Menu Informe Médico',
            cuerpoM: 'Debe Introducir un numero de cedula para poder realizar una consulta Gracias...',
        })

    }
}

handleClose = () => {
    this.setState({ mensajealerta: false })
  }



  render(){
      return(
        <div className="row">  
            <div className="cont-quimioc col-12">

                {this.state.mensajealerta === true &&
                    <Alert
                    tituloMensaje={this.state.tituloM}
                    cuerpoMensaje={this.state.cuerpoM}
                    open={this.state.mensajealerta}
                    handleClose={this.handleClose}
                    />
                }  

                <div className="row">
                    <div className="cont-tituloquimioc col-12">    
                        <span className="tituloquimioc">Informe de Ingreso</span>
                        <Link to="/"><span className="titulo-cerrarquimioc">X</span></Link>
                    </div>
                </div>
            
                <div className='row'>
                    <div className="cont-opcion col-3">
                        <label className="opcion">Cedula.</label>
                        <input
                            disabled={this.state.consulto}
                            className="form-control"
                            type="text"
                            name="cedula2"
                            id="cedula2"
                            autoComplete="off"
                            pattern="[0-9] required"
                            value={this.state.cedula2}
                            onChange={this.onChange.bind(this)}
                            onKeyPress={event => { if (event.key === 'Enter') {this.consultar()} }}
                        />
                        <i className="icono-izquierdac fa fa-search" onClick={this.consultar.bind(this)}> </i>
                    </div>
                    <div className="cont-opcion col-1">
                        <label className="opcion" >Edad.</label>
                        <input
                            className="form-control"
                            type="text"
                            name="edad2"
                            id="edad2"
                            onChange={this.onChange.bind(this)}
                            value={this.state.edad2}
                        />
                    </div>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Nombre.</label>
                        <input
                            className="form-control"
                            type="text"
                            name="nombre2"
                            id="nombre2"
                            autoComplete="off"
                            onChange={this.onChange.bind(this)}
                            value={this.state.nombre2}
                        />
                    </div>
                    <div className="cont-opcion col-2">
                        <label className="opcion" >Fecha Inicio Patologia.</label>
                        <input  
                            className="form-control"  
                            name="fechad2"
                            id="fechad2"
                            type="date" 
                            value={ moment(this.state.fechad2).format("YYYY-MM-DD") } 
                            onChange={ this.onChange.bind(this) } 
                        />
                    </div>
                </div>    
                <div className='row'>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Impresion Diagnostica.</label>
                        <textarea
                            className="form-control"
                            name="diagnostico2"
                            id="diagnostico2"
                            rows="2"
                            cols="135"
                            value={this.state.diagnostico2}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Sintomatología.</label>
                        <textarea
                            className="form-control"
                            name="sintoma2"
                            id="sintoma2"
                            rows="2"
                            cols="135"
                            value={this.state.sintoma2}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                </div>
                <div className='row'>    
                    <div className="cont-opcion col-2">
                            <label className="opcion" >T/Evolución.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="diase2"
                                id="diase2"
                                value={this.state.diase2}
                                onChange={this.onChange.bind(this)}
                            />
                    </div>
                    <div className="cont-opcion col-2">
                            <label className="opcion" >T/Hospitalización.</label>
                            <input
                                className="form-control"
                                type="text"
                                name="diash2"
                                id="diash2"
                                value={this.state.diash2}
                                onChange={this.onChange.bind(this)}
                            />
                    </div>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Examen Físico Hallasgos.</label>
                        <textarea
                            className="form-control"
                            name="examenf2"
                            id="examenf2"
                            rows="2"
                            cols="135"
                            value={this.state.examenf2}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Laboratorio.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="laboratorio2"
                            id="laboratorio2"
                            value="Laboratorio"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Ecografia.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="ecog2"
                            id="ecog2"
                            value="Ecografia"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Endoscopia.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="endoscopia2"
                            id="endoscopia2"
                            value="Endoscopia"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Ninguno.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="ninguno2"
                            id="ninguno2"
                            value="Ninguno"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Anexos.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="anexos2"
                            id="anexos2"
                            value="Anexos"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Radiología.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="radiologia2"
                            id="radiologia2"
                            value="Radiologia"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Tac.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="tac2"
                            id="tac2"
                            value="Tac"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Otros.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="otros2"
                            id="otros2"
                            value="Otros"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Biopsia.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="biopsia2"
                            id="biopsia2"
                            value="Biopsia"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Quirurgico.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="quirurgico2"
                            id="quirurgico2"
                            value="Quirurgico"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                    <div className="cont-opcion2 col-1">
                        <label className="opcion" >Médico.</label>
                        <input
                            className="form-control"
                            type="checkbox"
                            name="medico2"
                            id="medico2"
                            value="Medico"
                            onChange={this.onChange2.bind(this)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="cont-opcion col-6">
                        <label className="opcion" >Procedimiento Solicitado.</label>
                        <textarea
                            className="form-control"
                            name="procedimiento2"
                            id="procedimiento2"
                            rows="2"
                            cols="135"
                            value={this.state.procedimiento2}
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    <div className="representante col-6">
                            <label className="labelantecedentesescolar">Imprimir Firma. </label>
                            <label className="titulosn">
                                <input
                                    type="radio"
                                    className="opfirmasi"
                                    name="opfirma"
                                    id="opfirma"
                                    value="SI"
                                    checked={this.state.opfirma === 'SI'}
                                    onChange={this.onChange.bind(this)}
                                />
                            SI</label>
                            <label className="titulosn">
                                <input
                                    type="radio"
                                    className="opfirmano"
                                    name="opfirma"
                                    id="opfirma"
                                    value="NO"
                                    checked={this.state.opfirma === 'NO'}
                                    onChange={this.onChange.bind(this)}
                                />
                            NO</label>
                    </div>
                    <div className="botonesgeneral col-12">
                        <button className="botongr" onClick={this.guardar.bind(this)}>Enviar al Correo</button>
                        <button className="botongr" onClick={this.imprimir.bind(this)}>Imprimir</button>
                    </div>
                </div>


                {/* <div className="row">
                    <div className="botonesgeneral col-12">
                        <button className="botongr" onClick={this.guardar.bind(this)}>Enviar al Correo</button>
                        <button className="botongr" onClick={this.imprimir.bind(this)}>Imprimir</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
  }
}
export default Informeingreso;  